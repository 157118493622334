import React, { useRef, useState } from "react";
import Navbar from "./components/Navbar";
import NavbarSpec from "./components/NavbarSpec";
import Home from "./components/Home";
import Mission from "./components/Mission";
import Footer from "./components/Footer";
import Connect from "./components/Connect";
import Contact from "./components/Contact";
import Approach from "./components/Approach";
import WorshipHome from "./components/WorshipHome";
import WorshipSpec from "./components/WorshipSpec";
import WorshipReg from "./components/WorshipReg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  const missionRef = useRef(null);
  const projectRef = useRef(null);
  const helpRef = useRef(null);
  const contactRef = useRef(null);
  const worshipregHome = useRef(null); 
  const Worshipvision = useRef(null); 
  const worshipregRef = useRef(null); 

  const [navbarVisible, setNavbarVisible] = useState(null);

  const scrollToMission = () => {
    if (missionRef.current) {
      missionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToProject = () => {
    if (projectRef.current) {
      projectRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHelp = () => {
    if (helpRef.current) {
      helpRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToWorshipref = () => {
    if (worshipregRef.current) {
      worshipregRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToWorshipvision = () => {
    if (Worshipvision.current) {
      Worshipvision.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleNavbarVisibility = () => {
    setNavbarVisible(!navbarVisible);
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar
                  scrollToMission={scrollToMission}
                  scrollToProject={scrollToProject}
                  scrollToHelp={scrollToHelp}
                  scrollToContact={scrollToContact}
                  visible={navbarVisible}
                />
                <Home
                  scrollToMission={scrollToMission}
                  toggleNavbarVisibility={toggleNavbarVisibility}
                />
                <div ref={missionRef}>
                  <Mission />
                </div>
                <div ref={projectRef}>
                  <Approach />
                </div>
                <div ref={helpRef}>
                  <Connect />
                </div>
                <div ref={contactRef}>
                  <Contact />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/worshipspec"
            element={
              <>
                <NavbarSpec 
                  scrollToWorshipref={scrollToWorshipref}
                  scrollToWorshipvision={scrollToWorshipvision} 
                />
                <div ref={worshipregHome}>
                  <WorshipHome scrollToWorshipref={scrollToWorshipref}/>
                </div>
                <div ref={Worshipvision}>
                  <WorshipSpec />
                </div>
                <div ref={worshipregRef}>
                  <WorshipReg />
                </div>
                <Footer />
              </>
            }
          />
          <Route path="/worshipspec/success" element={<WorshipReg />} />
          <Route path="/worshipspec/cancel" element={<WorshipReg />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
