import React from "react";
import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../Next Logo only.png";

function Navbar({
  scrollToMission,
  scrollToProject,
  scrollToHelp,
  scrollToContact,
  visible,
}) {
  return (
    <>
      <Disclosure
        as="nav"
        className={`bg-customBlack fixed top-0 w-full z-20 backdrop-blur-16 transition-all duration-300 ${
          visible ? "lg:opacity-100 translate-y-0" : "lg:opacity-0 lg:translate-y-full"
        }`}
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
              <div className="relative flex h-20 items-center justify-between">
                <div className="flex flex-1 items-center sm:items-stretch justify-between w-full">
                  <div className="flex flex-shrink-0 items-center">
                    <img className="h-14 w-auto" src={logo} alt="Logo" />
                    <span className="text-white text-m items-center opacity-80 ml-3 font-bold .agdasima ">Pioneering Digital Kingdom</span>
                  </div>
                  <div className="hidden sm:mr-14 sm:flex items-center">
                    <div className="flex space-x-4">
                      <div className="text-white text-sm flex items-center opacity-80">
                        Help Us
                      </div>
                      <button
                        className="px-5 py-2 rounded-3xl text-black text-sm flex items-center"
                        style={{ background: "#7fb241" }}
                        onClick={scrollToHelp}
                      >
                        BUILD
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#D50032"
                          className="w-4 h-4 ml-2"
                        >
                          <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" inset-y-0 right-0 flex items-center ">
                  <div className="flex flex-grow">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-8 w-8"
                          aria-hidden="true"
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                          />
                        </svg>
                      )}
                    </Disclosure.Button>
                    {open && (
                      <Disclosure.Button className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 mr-2 mt-2">
                        <div
                          id="close"
                          className="bg-opacity-75 transition-opacity"
                        ></div>
                      </Disclosure.Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:block">
              <div className="fixed inset-0 z-50">
                <div className="absolute inset-0 bg-opacity-75 transition-opacity" />
                <section className="absolute right-0 top-0 pl-10 max-w-full flex z-50">
                  <Disclosure.Button className="text-gray-500 hover:text-gray-700">
                    <div
                      id="close"
                      className="fixed bg-black bg-opacity-75 transition-opacity z-8"
                    ></div>
                  </Disclosure.Button>
                  <div className="z-10 w-screen h-screen max-w-xs bg-customBlack text-white shadow-xl">
                    <div className="mt-6 flex items-center justify-between px-4">
                      <Disclosure.Button className="text-gray-500 hover:text-gray-700">
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </Disclosure.Button>
                    </div>

                    <div className="mt-10 px-4 overflow-auto">
                      <div className="flex flex-col">
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/" onClick={scrollToMission}>
                            - Vision - Mission - Mandate
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/" onClick={scrollToProject}>
                            - Projects
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/" onClick={scrollToHelp}>
                            - Help Us BUILD
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/" onClick={scrollToContact}>
                            - Contact Us
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/worshipspec">
                            - Worship Spec
                          </Link>
                        </div>
                      </div>
                      <div className="absolute -bottom-px mr-6 mt-6 mb-6 grid grid-cols-2 gap-x-6 md:grid-cols-2 lg:grid-cols-2 colortext">
                        <div className="mt-6 mx-auto mb-6 text-center lg:mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="mx-auto mb-6 h-8 w-8 text-primary dark:text-primary-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                            />
                          </svg>
                          <h6 className="font-medium">South Africa</h6>
                        </div>
                        <div className="mt-6 mx-auto mb-12 text-center lg:mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="mx-auto mb-6 h-8 w-8 text-primary dark:text-primary-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                          </svg>
                          <h6 className="font-medium">Cape Town, 7650</h6>
                        </div>
                        <div className="mt-6 mx-auto mb-6 text-center md:mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="mx-auto mb-6 h-8 w-8 text-primary dark:text-primary-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                          </svg>
                          <h6 className="font-medium">+27 71 302 9158</h6>
                        </div>
                        <div className="mt-6 mx-auto text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="mx-auto mb-6 h-8 w-8 text-primary dark:text-primary-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16"
                            />
                          </svg>
                          <h6 class="font-medium">hello@nextis.co.za</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Navbar;
