import React from "react";
import Slider from "react-slick";
import wacrbg from "../assets/carousel/Nextdg-cr-bg.png";
import wa0 from "../assets/carousel/wa1.jpg";
import wa1 from "../assets/carousel/wa1.jpg";
import wa2 from "../assets/carousel/wa2.jpg";
import wa3 from "../assets/carousel/wa3.jpg";
import wa4 from "../assets/carousel/wa4.jpg";
import wa5 from "../assets/carousel/wa5.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselImg from "./CarouselImg";

const tabContent = {
  nextis: {
    title: "Where worshipers are restored to the heart of worship",
    description: (
      <div>
         The vision of Worship SPEC is to build and harbour a safe, authentic and supportive online community for worship leaders/musicians/creatives where we can be equipped for the task at hand. 
         <br />
         <br />
We’ve witnessed restoration, re-alignment and awakening of hearts to our God-given call to worship during our yearly songwriting retreats, and we realized this is too crucial in our ministry spaces to only happen once a year. 
<br />
<br />
We, as worshippers, need to belong in a community where we are understood and where we can remind one another what it’s all about. Where we can cultivate healthy heart postures and share our challenges.
<br />
      </div>
    ),
    li1: [
      "In collaboration with “Next Is” we are now launching this online space where we can support, equip and pray for one another once a month. ",
    ],
    staticImg: wacrbg,
      images: [wa0, wa1, wa2, wa3, wa4, wa5],
  },
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dotsClass: "slick-dots custom-dots",
  appendDots: (dots) => (
    <div style={{ position: "relative", bottom: 0 }}>
      <ul style={{ margin: "0", padding: "0", listStyle: "none" }}>{dots}</ul>
    </div>
  ),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const WorshipSpec = () => {
  const content = tabContent["nextis"];

  return (
    <div className="py-14 bg-customBlack">
      <div className="px-3 md:px-0 py-10 mx-auto">
        <div className="w-full flex flex-wrap gap-4 h-12 text-3xl md:text-5xl font-bold text-white text-center">
        <div class="flex-[1]"></div>
  <div class="flex-[2] ">Support.</div>
  <div class="flex-[2]"> Prayer.</div>
  <div class="flex-[2] ">Equip.</div>
  <div class="flex-[2] ">Community.</div>
  <div class="flex-[1] "></div>
             
        </div>
        <div className="flex flex-col md:flex-row mt-10 md:mt-16 md:gap-12 p-3 md:p-0">
          <div className="flex-grow w-full md:w-3/5 md:px-14 flex flex-col justify-center">
            <div className="mt-6 text-white opacity-70">
              <div>{content.description}</div>
              {content.li1.map((item, index) => (
                <li className="mt-2" key={index}>
                  {item}
                </li>
              ))}
            </div>
          </div>

          <div className="flex-grow w-full md:w-2/5 mt-8 md:mt-0 md:ml-16 relative">
            <img
              src={content.staticImg}
              alt="img-1"
              className="approach-wa-img"
            />
            <div className="approach-2-img">
              <Slider {...settings}>
                {content.images.map((image, index) => (
                  <CarouselImg
                    key={index}
                    imageUrl={image}
                    index={index}
                    alt={`img-${index}`}
                    style={{ width: "100%" }}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorshipSpec;
