import React, { useState, useEffect, useMemo } from "react";
import mission from "../assets/mission.png";
import vision from "../assets/vision.png";
import mandate from "../assets/mandate.png";

function Mission() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const slides = useMemo(
    () => [
      {
        title: "Our Mission",
        description:
          "Is to revolutionize the digital Kingdom, empowering individuals and enabling churches and ministries to embody and share God's love by developing innovative platforms and resources that reclaim the vast amount of time spent online towards glorifying God, enriching digital discipleship, and nurturing connections that bridge virtual and physical communities, emphasizing the significance of nurturing authentic, in-person relationships within faith communities.",
        image: mission,
        button: "Mission",
        height: "600px",
      },
      {
        title: "Our Vision",
        description:
          "To be Pioneers of Digital Kingdom where God is at the heart of what we do, empowering individuals and communities to embody and share God's love, nurture transformative encounters, and make a global impact in the digital realm.",
        image: vision,
        button: "Vision",
        height: "500px",
      },
      {
        title: "Our Mandate",
        description:
          "We are dedicated to realizing our mission by guiding online endeavors towards glorifying God and enhancing digital discipleship. With purposeful action, we strive to unite virtual and physical communities, seeking deeper engagement and collaboration within faith communities.",
        image: mandate,
        button: "Mandate",
        height: "500px",
      },
    ],
    []
  );

  useEffect(() => {
    const img = new Image();
    img.src = slides[currentSlide].image;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const containerHeight = window.innerWidth / aspectRatio; // Height calculated based on screen width and aspect ratio
      setContainerHeight(containerHeight);
    };
  }, [currentSlide, slides]);

  const goToSlide = (index) => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentSlide(index);
      setFadeIn(true);
    }, 500); // Adjust the duration based on your preference
  };

  return (
    <>
      <div className="mission hidden md:block bg-customBlack lg:overflow-hidden">
        <div
          className="pt-10 pb-20 relative"
          style={{ height: `${containerHeight}px` }}
        >
          <div
            className={`lg:overflow-hidden flex transition-opacity duration-500 ${
              fadeIn ? "opacity-100" : "opacity-0"
            }`}
            style={{ height: `${containerHeight}px` }}
          >
            <div className="md:ml-10 md:w-3/5 px-4 text-left relative z-10 flex  justify-center items-center ">
              <div className="rounded-3xl shadow-[0 1px 3px -1px rgba(0,0,0,0.035), 0 5px 5px -2px rgba(0,0,0,0.02)] dark:bg-[hsla(0,0%,5%,0.5)] dark:shadow-black/5 backdrop-blur-[5px]">
                <div className="p-8">
                  <div className="text-3xl md:text-5xl font-bold text-white">
                    {slides[currentSlide].title}
                  </div>
                  <div className="mt-4 text-white opacity-70 z-10">
                    {slides[currentSlide].description}
                  </div>
                  <div className="flex flex-1 items-center sm:items-stretch mt-4">
                    {slides.map((slide, index) => (
                      <button
                        key={index}
                        className={`text-white px-4 mr-3 py-2 rounded-3xl border border-white border-opacity-70 ${
                          index === currentSlide ? 'text-black bg-customGreen' : ''
                        }`}
                        onClick={() => goToSlide(index)}
                      >
                        {slide.button}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full right-0 absolute z-0 ">
              <img
                src={slides[currentSlide].image}
                alt={slides[currentSlide].title}
                className="absolute right-0"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mission bg-customBlack block md:hidden pt-8">
        <div
          className="pt-10 relative"
          style={{ height: `${slides[currentSlide].height}px` }}
        >
          <div
            className={`lg:overflow-hidden flex transition-opacity duration-500 ${
              fadeIn ? "opacity-100" : "opacity-0"
            }`}
            style={{ height: `auto` }}
          >
            <div className="md:ml-10 md:w-3/5 px-4 mt-[150px] text-left relative z-10 flex  justify-center items-center ">
              <div className="rounded-3xl shadow-[0 1px 3px -1px rgba(0,0,0,0.035), 0 5px 5px -2px rgba(0,0,0,0.02)] dark:bg-[hsla(0,0%,5%,0.5)] dark:shadow-black/5 backdrop-blur-[5px]">
                <div className="p-4">
                  <div className="text-3xl md:text-5xl font-bold text-white">
                    {slides[currentSlide].title}
                  </div>
                  <div className="mt-4 text-white opacity-70 z-10">
                    {slides[currentSlide].description}
                  </div>
                  <div className="flex flex-1 items-center sm:items-stretch mt-4">
                    {slides.map((slide, index) => (
                      <button
                        key={index}
                        className={`text-white px-4 mr-3 py-2 rounded-3xl border border-white border-opacity-70 ${
                          index === currentSlide ? 'text-black bg-customGreen' : ''
                        }`}
                        onClick={() => goToSlide(index)}
                      >
                        {slide.button}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full right-0 absolute z-0 ">
              <img
                src={slides[currentSlide].image}
                alt={slides[currentSlide].title}
                className="absolute right-0"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
