import React, { useState } from "react";
import Slider from "react-slick";
import nxcrbg from "../assets/carousel/Next-cr-bg.png";
import nxdgcrbg from "../assets/carousel/Nextdg-cr-bg.png";
import wacrbg from "../assets/carousel/WA-cr-bg.png";
import aucrbg from "../assets/carousel/AU-cr-bg.png";
import cucrbg from "../assets/carousel/CU-cr-bg.png";
import micrbg from "../assets/carousel/MI-cr-bg.png";
import au1 from "../assets/carousel/au1.jpg";
import au2 from "../assets/carousel/au2.jpg";
import au3 from "../assets/carousel/au3.jpg";
import au4 from "../assets/carousel/au4.jpg";
import nx1 from "../assets/carousel/nx1.jpg";
import nx2 from "../assets/carousel/nx2.jpg";
import nx3 from "../assets/carousel/nx3.jpg";
import nx4 from "../assets/carousel/nx4.jpg";
import wa0 from "../assets/carousel/wa0.png";
import wa1 from "../assets/carousel/wa1.jpg";
import wa2 from "../assets/carousel/wa2.jpg";
import wa3 from "../assets/carousel/wa3.jpg";
import wa4 from "../assets/carousel/wa4.jpg";
import wa5 from "../assets/carousel/wa5.jpg";
import ch1 from "../assets/carousel/ch1.jpg";
import ch2 from "../assets/carousel/ch2.jpg";
import ch3 from "../assets/carousel/ch3.jpg";
import ch4 from "../assets/carousel/ch4.jpg";
import ch5 from "../assets/carousel/ch5.jpg";
import ch6 from "../assets/carousel/ch6.jpg";
import mi1 from "../assets/carousel/mi1.jpg";
import mi2 from "../assets/carousel/mi2.jpg";
import mi3 from "../assets/carousel/mi3.jpg";
import mi4 from "../assets/carousel/mi4.jpg";
import mi5 from "../assets/carousel/mi5.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselImg from "./CarouselImg";

function Approach() {
  const [selectedTab, setSelectedTab] = useState("nextis");

  const tabContent = {
    nextis: {
      buttonText: "Next Is",
      title: "Empowering",
      description: (
        <div>
          Our mission is to equip the body and all the partners/benefactors we
          work with for Digital Ministry and growing their online communities,
          directly empowering our mandate. Currently, we are focusing on
          developing our digital platform. While doing so, we also capture
          moments where God moves in the physical space, whether through
          interviews or worship sets.
          <br />
          <strong>To Date</strong>
        </div>
      ),
      li1: [
        "Number of partners/benefactors: 7",
        "Digital communities created: 4",
        "Projects completed: 50+",
        "Photos taken: 10,000+",
        "Videos Hours: 1400+",
      ],
      staticImg: nxcrbg,
      images: [nx1, nx2, nx3, nx4],
    },
    digital: {
      buttonText: "Digital",
      title: "Where we bridge physical and digital Faith communities",
      description: (
        <div>
          {" "}
          We believe that Digital has a vital role to play within the Kingdom,
          and traditional thinking is not enough to reach a Digital age. We are
          developing new tools and resources to equip the body and bring people
          closer to God.
          <br />
          <strong>Our Goal:</strong>
        </div>
      ),
      li1: [
        "Features Rich Platform",
        "Customizable to Ministry or Church needs",
        "Encourage User Engagement",
        "Ultimately giving a believer a purpose online",
      ],
      staticImg: nxdgcrbg,
      images: [],
    },
    worshipawake: {
      buttonText: "Worship Awake",
      title: "Where worshipers are restored to the heart of worship",
      description: (
        <div>
          We at Next Is recognize the important role that worship has to play in
          digital kingdom. Therefore, we partnered with Worship Awake to see the
          fruit of this movement not just in the physical but also in the
          digital space.
          <br />
          To Date:
        </div>
      ),
      li1: [
        "Filmed and produced 3 music videos",
        "Recorded and released 6 Songs",
        "Hosted 5 songwriting retreats",
      ],
      staticImg: wacrbg,
      images: [wa0, wa1, wa2, wa3, wa4, wa5],
    },
    alwyn: {
      buttonText: "Alwyn Uys",
      title: "a prophetic voice not only in South Africa but also internationally.",
      description: (
        <div>
          {" "}
          This mutual partnership is 
          characterized by the exploration of the digital landscape and highlighting the 
          significance of building healthy Christian community. Through his teachings on 
          prophecy, Alwyn has cultivated a substantial online following of 135,000 YouTube 
          subscribers across 303 videos and an impressive total watch time of 100,000 hours on YouTube.
          <br />
        </div>
      ),
      li1: [
      ],
      staticImg: aucrbg,
      images: [au1, au2, au3, au4],
    },
    church: {
      buttonText: "Church",
      title: "Local gathering for spiritual family and fellowship.",
      description: `The Church plays a vital role in a Digital kingdom model. We need to train and equip the Physical Church to facilitate digital mission and effectively bridge the gap so no one gets left behind. Not all churches have the means to effectively be Digital.`,
      li1: [
        "We are called to equip and train Churches.",
        "We have assisted more than 20 Churches.",
        "The list of needs is more than we can help.",
      ],
      staticImg: cucrbg,
      images: [ch1, ch2, ch3, ch4, ch5, ch6],
    },
    minestries: {
      buttonText: "Ministries",
      title: "Empowering Ministries for Digital Outreach",
      description: `Our mission extends to empowering ministries for effective digital outreach, providing support and resources to enhance their online presence and impact.`,
      li1: [
        "We collaborate with ministries to develop tailored strategies, digital platforms, and content that resonate with their audience and further their mission.",
        "Together, we aim to bridge the gap between traditional ministry approaches and the digital age, ensuring that ministries can effectively engage and minister to individuals in the digital realm.",
      ],
      staticImg: micrbg,
      images: [mi1, mi2, mi3, mi4, mi5],
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dotsClass: "slick-dots custom-dots",
    appendDots: (dots) => (
      <div style={{ position: "relative", bottom: 0 }}>
        <ul style={{ margin: "0", padding: "0", listStyle: "none" }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const settingsTabs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplay: false,
    dotsClass: "slick-dots custom-dots",
    appendDots: (dots) => (
      <div style={{ position: "relative", bottom: 0 }}>
        <ul style={{ margin: "0", padding: "0", listStyle: "none" }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-14 bg-customBlack">
      <div className="px-3 md:px-0 py-10 mx-auto">
        <div className="text-3xl md:text-5xl font-bold text-white text-center">
          Your Help in
        </div>
        <div className="overflow-y-auto hidden lg:flex lg:justify-center mt-10">
          <div className="grid w-[60rem] grid-cols-6 gap-2 rounded-3xl">
            {[
              "nextis",
              "digital",
              "worshipawake",
              "alwyn",
              "church",
              "minestries",
            ].map((country) => (
              <div
                key={country}
                className="border border-white border-opacity-70 rounded rounded-3xl"
              >
                <input
                  type="radio"
                  name="option"
                  id={country}
                  value={country}
                  className="peer hidden"
                  checked={selectedTab === country}
                  onChange={() => setSelectedTab(country)}
                />
                <label
                  htmlFor={country}
                  className={`block cursor-pointer select-none rounded-3xl p-2 text-center ${
                    selectedTab === country
                      ? "bg-customGreen text-black"
                      : "text-white text-opacity-70"
                  }`}
                >
                  {tabContent[country].buttonText}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="overflow-y-auto block lg:hidden mt-10">
          <Slider {...settingsTabs}>
            {Object.keys(tabContent).map((country) => (
              <div
                key={country}
                className="grid w-[60rem] grid-cols-6 gap-2 rounded-3xl"
              >
                <div
                  className={`border border-white border-opacity-70 rounded rounded-3xl ${
                    selectedTab === country ? "bg-customGreen text-black" : ""
                  }`}
                  style={{ margin: "0 5px" }}
                >
                  <label
                    htmlFor={country}
                    className="block cursor-pointer select-none rounded-3xl p-2 text-center text-white text-opacity-70"
                    onClick={() => setSelectedTab(country)}
                  >
                    {tabContent[country].buttonText}
                  </label>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="flex flex-col md:flex-row mt-10 md:mt-16 md:gap-12 p-3 md:p-0">
          <div className="flex-grow w-full md:w-3/5 md:px-14 flex flex-col justify-center">
            <div className="text-3xl md:text-5xl font-bold text-white">
              {tabContent[selectedTab].title}
            </div>
            <div className="mt-6 text-white opacity-70">
              <div>{tabContent[selectedTab].description}</div>
              {tabContent[selectedTab].li1.map((item, index) => (
                <li className="mt-2" key={index}>
                  {item}
                </li>
              ))}
            </div>
          </div>

          <div className="flex-grow w-full md:w-2/5 mt-8 md:mt-0 md:ml-16 relative">
            <img
              src={tabContent[selectedTab].staticImg}
              alt="img-1"
              className="approach-1-img"
            />
            {tabContent[selectedTab].images.length > 1 ? (
              <div className="approach-2-img">
                <Slider {...settings}>
                  {tabContent[selectedTab].images.map((image, index) => (
                    <CarouselImg
                      key={index}
                      imageUrl={image}
                      index={index}
                      alt={`img-${index}`}
                      style={{ width: "100%" }}
                    />
                  ))}
                </Slider>
              </div>
            ) : tabContent[selectedTab].images.length === 1 ? (
              <img
                src={tabContent[selectedTab].images[0]}
                alt={`img-0`}
                className="approach-2-img"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Approach;
