import React from "react";

function ProgressWS({ name, disc, value, max }) {
  // Clamp the width percentage to a maximum of 100%
  const widthPercentage = Math.min((value / max) * 100, 100);

  // Define colors based on percentage ranges
  const getBarColor = () => {
    if (widthPercentage >= 100) return '#FF5733'; // Color for 100%
    if (widthPercentage >= 90) return '#FFC300'; // Color for 90% to < 100%
    return '#7fb241'; // Default color
  };

  return (
    <div className="flex flex-col my-6">
      <div className="text-white mb-2 font-bold ml-1">{name}</div>
      <div className="text-white mb-1 text-xs ml-1">{disc}</div>
      <div
        className="h-5 rounded-lg"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax={max}
        style={{ background: "#1C1C1C", overflow: 'hidden' }} // Ensure no overflow
      >
        <div
          className="h-5 text-xs text-right rounded-lg"
          style={{
            width: `${widthPercentage}%`,
            backgroundColor: getBarColor(), // Apply color based on percentage
            color: '#fff' // Text color
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
}

export default ProgressWS;
