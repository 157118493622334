import React from "react";

function ProgressBar({ name, disc, width }) {
  return (
    <>
      <div className="flex flex-col my-6">
        <div className="text-white mb-2 font-bold ml-1">{name}</div>
        <div className="text-white mb-1 text-xs ml-1">{disc}</div>
        <div
          className="h-5 rounded-lg"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ background: "#1C1C1C" }}
        >
          <div
            className="h-5 text-xs text-right rounded-lg bg-customGreen pr-1"
            style={{ width: width }}
          >{width}</div>
        </div>
      </div>
    </>
  );
}

export default ProgressBar;
