import React from 'react';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

const DialogMessage = ({ open, onClose, title, message }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={onClose} className="dialog-overlay">
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-customBlack rounded-lg max-w-sm w-full">
          <div className="px-4 py-5">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white opacity-80">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-white opacity-60">
                {message}
              </p>
            </div>
          </div>
          <div className="px-4 py-3 flex flex-row-reverse sm:px-6 justify-center" style={{ background: "#1A1A1A" }}>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-customGreen text-black px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto"
              onClick={() => {
                navigate("/worshipspec");
                onClose();
              }}
            >
              Done
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DialogMessage;
