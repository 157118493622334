import React, { useEffect, useState } from "react";
import { Disclosure, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import WorshipDonate from './WorshipDonate'; 
import logo1 from "../Next Logo only.png";
import logo2 from "../worship-awake-logo-primary.png";

function NavbarSpec({ scrollToWorshipref, scrollToWorshipvision, visible }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("seed") === "true") {
      openModal();
    }
  }, [location.search]);

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-customBlack fixed top-0 w-full z-20 backdrop-blur-16 transition-all duration-300"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
              <div className="relative flex h-20 items-center justify-between">
                <div className="flex flex-1 items-center sm:items-stretch justify-between w-full">
                  <div className="flex flex-shrink-0 items-center">
                    <span className="mr-2 text-white text-m items-center opacity-80 ml-3 font-bold .agdasima">An Initiative by </span>
                    <img className="mr-2 h-14 w-auto" src={logo2} alt="Logo" />
                    <img className="h-14 w-auto" src={logo1} alt="Logo" />
                  </div>
                  <div className="hidden sm:mr-14 sm:flex items-center">
                    <div className="flex space-x-4">
                      <div className="text-white text-sm flex items-center opacity-80 cursor-pointer" onClick={openModal}>
                        Help Us BUILD
                      </div>
                      <button
                        className="px-5 py-2 rounded-3xl text-black text-sm flex items-center"
                        style={{ background: "#7fb241" }}
                        onClick={openModal}
                      >
                        SPEC
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#D50032"
                          className="w-4 h-4 ml-2"
                        >
                          <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="inset-y-0 right-0 flex items-center ">
                  <div className="flex flex-grow">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-8 w-8"
                          aria-hidden="true"
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                          />
                        </svg>
                      )}
                    </Disclosure.Button>
                    {open && (
                      <Disclosure.Button className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 mr-2 mt-2">
                        <div
                          id="close"
                          className="bg-opacity-75 transition-opacity"
                        ></div>
                      </Disclosure.Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:block">
              <div className="fixed inset-0 z-50">
                <div className="absolute inset-0 bg-opacity-75 transition-opacity" />
                <section className="absolute right-0 top-0 pl-10 max-w-full flex z-50">
                  <Disclosure.Button className="text-gray-500 hover:text-gray-700">
                    <div
                      id="close"
                      className="fixed bg-black bg-opacity-75 transition-opacity z-8"
                    ></div>
                  </Disclosure.Button>
                  <div className="z-10 w-screen h-screen max-w-xs bg-customBlack text-white shadow-xl">
                    <div className="mt-6 flex items-center justify-between px-4">
                      <Disclosure.Button className="text-gray-500 hover:text-gray-700">
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </Disclosure.Button>
                    </div>

                    <div className="mt-10 px-4 overflow-auto">
                      <div className="flex flex-col">
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/worshipspec" onClick={scrollToWorshipvision}>
                            - Vision 
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen">
                          <Link to="/worshipspec" onClick={scrollToWorshipref}>
                            - Registration 
                          </Link>
                        </div>
                        <div className="mt-2 mb-2 cursor-pointer hover:text-customGreen" onClick={openModal}>
                          - Help Us BUILD
                        </div>
                      </div>
                      <div className="absolute -bottom-px mr-6 mt-6 mb-6 grid grid-cols-2 gap-x-6 md:grid-cols-2 lg:grid-cols-2 colortext">
                        <div className="mt-6 mx-auto text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="mx-auto mb-6 h-8 w-8 text-primary dark:text-primary-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16"
                            />
                          </svg>
                          <h6 className="font-medium">worshipawake@nextis.co.za</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Modal for Help Us BUILD */}
      <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-20 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="bg-customBlack rounded-lg max-w-sm sm:max-w-lg lg:max-w-xl mx-auto p-4 z-20">
            <WorshipDonate />

            <button
              onClick={closeModal}
              className="mt-4 bg-red-500 text-white p-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NavbarSpec;
