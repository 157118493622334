import React from "react";

function Footer() {
  return (
    <div
      style={{ background: "#181818" }}
      className="min-h-10 flex justify-center"
    >
      <div className="text-white text-xs flex justify-center items-center w-100 opacity-75">
        © Copyright 2024, All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
