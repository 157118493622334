import React, { useState, useEffect } from "react";

function CarouselImg({ imageUrl, index }) {
  const [base64String, setBase64String] = useState("");

  useEffect(() => {
    fetchImage(imageUrl);
  }, [imageUrl, index]);

  const fetchImage = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64String(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 453 473"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern id={`image-${index}`} x="0" y="0" width="1" height="1">
          <image
            xlinkHref={base64String}
            width="100%"
            height="100%"
            preserveAspectRatio="none"
          />
        </pattern>
      </defs>
      <path
        d="M10 50C10 27.9086 27.9086 10 50 10H402.324C427.745 10 446.717 33.4027 441.459 58.2738L451.243 60.3422L441.459 58.2738L362.601 431.274C358.691 449.768 342.368 463 323.466 463H50C27.9086 463 10 445.091 10 423V50Z"
        fill={`url(#image-${index})`}
        stroke="#121212"
        strokeWidth="20"
      />
    </svg>
  );
}

export default CarouselImg;
