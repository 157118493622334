import React, { useState } from "react";

function WorshipDonat() {
  const [selectedPlandn, setselectedPlandn] = useState("2");
  const [selectedAmountdn, setselectedAmountdn] = useState("R 250");
  const [cyclesNodn, setcyclesNodn] = useState("3");
  const [firstNamedn, setfirstNamedn] = useState("");
  const [lastNamedn, setlastNamedn] = useState("");
  const [emailnd, setemailnd] = useState("");
  const [phoneNodn, setphoneNodn] = useState("");
  const [itemName] = useState("Donation SPEC");
  const [showCustomAmountdn, setshowCustomAmountdn] = useState(false); // State to manage visibility of custom amount input
  const [isemailndTouched, setIsemailndTouched] = useState(false);
  const [isPhoneToucheddn, setisPhoneToucheddn] = useState(false);



  const handleAmountClickdn = (amount) => {
    setselectedAmountdn(amount);
  };

  const handleOptionChangedn = (event) => {
    setselectedPlandn(event.target.value);
  };

  const handleCyclesChangedn = (cyclesdn) => {
    setcyclesNodn(cyclesdn);
  };

  const emailndRegexdn = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegexdn = /^(?:\+27|0)[1-9]\d{8}$/;

  return (
    <>
      <div className="py-14 bg-customBlack">
        <div className="container px-3 md:px-8 py-10 mx-auto">
          <div className="text-3xl md:text-5xl font-bold text-white text-center">
          Make a difference within the Worship Space<span style={{ color: "#7fb241" }}>!!</span>
          </div>
          <div className="flex flex-col md:flex-row mt-10 md:mt-14 md:gap-12 p-3 md:p-0">

            <div className="flex-grow w-full md:w-3/5 mt-8 md:mt-0 md:ml-16">
              <form
                action="https://www.payfast.co.za/eng/process"
                //  action="https://sandbox.payfast.co.za​/eng/process"
                method="post"
              >
                {/* Payfast from */}
                <input type="hidden" name="merchant_id" value="17661491" /> 
                {/* <input type="hidden" name="merchant_id" value="10033012" /> */}
                <input
                  type="hidden"
                  name="merchant_key"
                  value="lyymgvppkik3s"
                />
                {/* <input
                  type="hidden"
                  name="merchant_key"
                  value="m8oucxumfetwl"
                /> */}
                <input
                  type="hidden"
                  name="return_url"
                  value="http://nextis.co.za/success"
                />
                <input
                  type="hidden"
                  name="notify_url"
                  value="http://nextis.co.za/success"
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  value="http://nextis.co.za/cancel"
                />
                <input
                  type="hidden"
                  name="amount"
                  value={selectedAmountdn.substring(1)}
                />
                <input type="hidden" name="item_name" value={itemName} />
                <input type="hidden" name="name_first" value={firstNamedn} />
                <input type="hidden" name="name_last" value={lastNamedn} />
                <input type="hidden" name="emailnd_address" value={emailnd} />
                <input type="hidden" name="cell_number" value={phoneNodn} />
                {selectedPlandn !== "2" && (
                  <>
                    <input type="hidden" name="subscription_type" value={"1"} />
                    <input
                      type="hidden"
                      name="frequency"
                      value={selectedPlandn}
                    />
                     <input type="hidden" name="cycles" value={cyclesNodn}/>
                  </>
                )}

                {/* Payfast from */}
                <div className="flex items-center justify-center">
  <div className="grid w-[30rem] grid-cols-2 gap-2 rounded-3xl border border-white border-opacity-70">
    <div>
      <input
        type="radio"
        name="payment-optiondn"
        id="2"
        value="2"
        className="peer hidden"
        onChange={handleOptionChangedn}
        checked={selectedPlandn === "2"}
      />
      <label
        htmlFor="2"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        One-Time
      </label>
    </div>

    <div>
      <input
        type="radio"
        name="payment-optiondn"
        id="3"
        value="3"
        className="peer hidden"
        onChange={handleOptionChangedn}
        checked={selectedPlandn === "3"}
      />
      <label
        htmlFor="3"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        Monthly
      </label>
    </div>

    {/* Added flex-grow class <div>
      <input
        type="radio"
        name="payment-optiondn"
        id="6"
        value="6"
        className="peer hidden"
        onChange={handleOptionChangedn}
        checked={selectedPlandn === "6"}
      />
      <label
        htmlFor="6"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen peer-checked:text-black text-white text-opacity-70"
      >
        Annually
      </label>
    </div> */}
  </div>
</div>
{selectedPlandn === "3" && (
  <div className="flex justify-center items-center w-full p-2">
    <div className="flex justify-between w-full max-w-sm border border-white border-opacity-70 rounded-3xl">
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNodn"
          id="3_months"
          value="3"
          className="peer hidden"
          onChange={(e) => handleCyclesChangedn(e.target.value)}
        />
        <label
          htmlFor="3_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNodn === "3" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 3 months
        </label>
      </div>
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNodn"
          id="6_months"
          value="6"
          className="peer hidden"
          onChange={(e) => handleCyclesChangedn(e.target.value)}
        />
        <label
          htmlFor="6_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNodn === "6" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 6 months
        </label>
      </div>
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNodn"
          id="12_months"
          value="12"
          className="peer hidden"
          onChange={(e) => handleCyclesChangedn(e.target.value)}
        />
        <label
          htmlFor="12_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNodn === "12" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 12 months
        </label>
      </div>
    </div>
  </div>
)}
                <div className="mt-6 grid grid-cols-1 gap-x-3 md:gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3 ">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        placeholder="First Name"
                        className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
                        onChange={(e) => setfirstNamedn(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 mt-3 md:mt-0">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        placeholder="Last Name"
                        className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
                        onChange={(e) => setlastNamedn(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-x-3 md:gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3 mt-3 md:mt-0">
                  <div className="mt-2">
                    <input
                      type="emailnd"
                      name="emailnd"
                      id="emailnd"
                      placeholder="emailnd Address"
                      className={`block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70`}
                      onChange={(e) => setemailnd(e.target.value)}
                      onBlur={() => setIsemailndTouched(true)}
                    />
                    {isemailndTouched && !emailndRegexdn.test(emailnd) && (
                      <p className="text-red-500 text-sm mt-1">Please enter a valid emailnd address.</p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3 mt-3 md:mt-0">
                  <div className="mt-2">
                    <input
                      type="tel"
                      name="phoneNodn"
                      id="phoneNodn"
                      placeholder="Phone Number"
                      className={`block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70`}
                      onChange={(e) => setphoneNodn(e.target.value)}
                      onBlur={() => setisPhoneToucheddn(true)}
                    />
                    {isPhoneToucheddn && !phoneRegexdn.test(phoneNodn) && (
                      <p className="text-red-500 text-sm mt-1">Please enter a valid phone number.</p>
                    )}
                  </div>
                </div>
                </div>
                <div className="flex flex-wrap mt-4 gap-4">
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmountdn === "R 250"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(false); // Show the custom amount input box
      handleAmountClickdn("R 250"); // Update the selected amount to "Own Amount"
    }}
  >
    R 250
  </div>
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmountdn === "R 500"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(false); // Show the custom amount input box
      handleAmountClickdn("R 500"); // Update the selected amount to "Own Amount"
    }}
  >
    R 500
  </div>
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmountdn === "R 1,000"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(false); // Show the custom amount input box
      handleAmountClickdn("R 1,000"); // Update the selected amount to "Own Amount"
    }}
  >
    R 1,000
  </div>
  <div
    className={`px-5 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmountdn === "R 2,500"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(false); // Show the custom amount input box
      handleAmountClickdn("R 2,500"); // Update the selected amount to "Own Amount"
    }}
  >
    R 2,500
  </div>
  <div
    className={`px-4 py-2 border border-white  text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmountdn === "R 5,000"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(false); // Show the custom amount input box
      handleAmountClickdn("R 5,000"); // Update the selected amount to "Own Amount"
    }}
  >
    R 5,000
  </div>
  <div
    className={`px-4 py-2 border border-white  text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      showCustomAmountdn === true
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setshowCustomAmountdn(true); // Show the custom amount input box
      handleAmountClickdn(""); // Update the selected amount to "Own Amount"
    }}
  >
    Own :
  </div>
  {showCustomAmountdn && (
        <div className="pt-4 flex w-full">
          <div className="flex items-center justify-between w-full">
            <input
              type="text"
              id="custom-amount"
              name="custom-amount"
              className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
              placeholder="Enter amount"
              onChange={(e) => handleAmountClickdn("R " + e.target.value)}
            />
          </div>
        </div>
      )}
    </div>

                <div className="pt-4 flex w-full">
                  <button
                    type="submit"
                    className="bg-customGreen  rounded-md w-full py-2 text-md"
                    // onClick={() => setOpen(true)}
                  >
                     {`Give ${selectedAmountdn} ${selectedPlandn === "2" ? "" : `monthly for ${cyclesNodn} months`}`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}

export default WorshipDonat;
