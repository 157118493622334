import React, { useState, useEffect } from "react";
import ProgressWS from "./ProgressWS";
import { useLocation, useNavigate } from "react-router-dom";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import axios from 'axios';
import DialogMessage from './DialogMessage';

function WorshipReg() {
  const domain = "https://nextis.co.za";
  //const domain = "http://localhost:5001";
  const [selectedPlan, setSelectedPlan] = useState("2");
  const [selectedAmount] = useState(250);
  const [cyclesNo, setCyclesNo] = useState("1");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("trail");
  const [itemName] = useState("Spec Fee");
  const [challenges, setChallenges] = useState("");
  const [needs, setNeeds] = useState("");
  const [church, setChurch] = useState("");
  const [ideal_time, setIdeal_time] = useState("");
  const [open, setOpen] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const [isEmailDuouble, setIsEmailDuouble] = useState(false);
  const [isTimeTouched, setIsTimeTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [entryId, setEntryId] = useState(null);
  const [entryCount, setEntryCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNo("");
    setChallenges("");
    setNeeds("");
    setChurch("");
    setIdeal_time("");
    setSelectedPlan("2");
    setCyclesNo("1");
    setIsEmailTouched(false);
    setIsPhoneTouched(false);
    setIsEmailDuouble(false);
    setIsTimeTouched(false);
    setIsSubmitting(false);
    setEntryId(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleOptionChange = (event) => {
    console.log("WorshipReg Plan Change:", event.target.value);
    setSelectedPlan(event.target.value);
  };

  const handleCyclesChange = (cycles) => {
    setCyclesNo(cycles);
  };

  function disabledHours() {
    return [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23];
  }

  const handleCombinedChange3 = (e) => {
    handleOptionChange(e);
    handleCyclesChange("3");
  };

  const handleCombinedChange1 = (e) => {
    handleOptionChange(e);
    handleCyclesChange("1");
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const status = queryParams.get('status');

    if (id && status) {
      if (status === 'success' || status === 'failed') {
        updatePaymentStatus(id, status);
      }
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const fetchEntryCount = async () => {
      try {
        const response = await axios.get(`${domain}/api/entry-count`);
        setEntryCount(response.data.count);
      } catch (error) {
        console.error("Error fetching entry count:", error);
      }
    };

    fetchEntryCount();
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+27|0)[1-9]\d{8}$/;

  const handleBlur = () => {
    setIsEmailTouched(true);
    setIsEmailDuouble(false);
  };

  const calculateAmount = () => {
    if (selectedPlan === "3") {
      return (selectedAmount / parseInt(cyclesNo)).toFixed(2);
    }
    return selectedAmount.toFixed(2);
  };

  const submitForm = async (e) => {
    e.preventDefault();

      // Check if ideal_time is empty
  if (!ideal_time) {
    setSuccessMessage('Preferred meeting time is required!');
    setIsTimeTouched(true);
    setOpen(true);
    return; // Stop form submission
  }
    // Start form submission
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${domain}/api/register`, {
        firstName,
        lastName,
        email,
        phoneNo,
        amount: calculateAmount(),
        cyclesNo,
        itemName,
        paymentStatus,
        challenges,
        needs,
        church,
        ideal_time,
      });

      const entryId = response.data.id;
      setEntryId(entryId);

      setSuccessMessage("Your registration was successful. Please check your email for more details!");
      setOpen(true);

      resetForm();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log('Email is already registered!!');
        setIsEmailDuouble(true);
        setSuccessMessage('Email is already registered!!');
        setOpen(true);
        setIsSubmitting(false);
      } else {
        console.error('There was an error submitting the form!', error);
      }
    }
  };

  const updatePaymentStatus = async (id, status) => {
    try {
      await axios.post(`${domain}/api/update-payment-status`, {
        id,
        paymentStatus: status,
      });
      setPaymentStatus(status);
    } catch (error) {
      console.error('There was an error updating the payment status!', error);
    }
  };

  const dialogTitle = paymentStatus === "success"
    ? "Payment Successful"
    : paymentStatus === "pending"
    ? "Payment Pending"
    : paymentStatus === "failed"
    ? "Payment Failed"
    : "Registration Status";

  const dialogMessage = paymentStatus === "success"
    ? "Your registration was successful. Please check your email for more details."
    : paymentStatus === "pending"
    ? "Your registration is successful, but your payment is still pending. Please check your email for more details and payment options."
    : paymentStatus === "failed"
    ? "Your registration is on hold as payment failed. Please check your email for more details and payment options."
    : successMessage;

  return (
    <>
      <div className="py-14 bg-customBlack">
        <div className="container px-3 md:px-8 py-10 mx-auto">
          <div className="text-3xl md:text-5xl font-bold text-white text-center">
            Join the <span style={{ color: "#7fb241" }}>TRIBE</span>!
          </div>
          <div className="flex flex-col items-center mt-10 md:mt-14 md:gap-3 p-3 md:p-0">
            <div className="flex-grow w-full md:w-4/5 mt-8 md:mt-0 mx-auto">
              <form id="payfast-form" onSubmit={submitForm} method="post">
                <input type="hidden" name="merchant_id" value="17661491" />
                <input type="hidden" name="merchant_key" value="lyymgvppkik3s" />
                <input type="hidden" name="return_url" value="" />
                <input type="hidden" name="notify_url" value="" />
                <input type="hidden" name="cancel_url" value="" />
                <input type="hidden" name="amount" value={calculateAmount()} />
                <input type="hidden" name="item_name" value={itemName} />
                <input type="hidden" name="name_first" value={firstName} />
                <input type="hidden" name="name_last" value={lastName} />
                <input type="hidden" name="email_address" value={email} />
                <input type="hidden" name="cell_number" value={phoneNo} />

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 md:mt-8">
                  <div>
                    <input
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleBlur}
                      placeholder="Email"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                      required
                    />
                    {isEmailTouched && !emailRegex.test(email) && (
                      <span className="text-red-500 text-sm">Invalid email format</span>
                    )}
                    {isEmailDuouble && (
                      <span className="text-red-500 text-sm">Email is already registered</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="phoneNo"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      onBlur={() => setIsPhoneTouched(true)}
                      placeholder="Phone No"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                      required
                    />
                    {isPhoneTouched && !phoneRegex.test(phoneNo) && (
                      <span className="text-red-500 text-sm">Invalid phone number format</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="church"
                      value={church}
                      onChange={(e) => setChurch(e.target.value)}
                      placeholder="Current Church Affiliation"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                    />
                  </div>
                  <div>
                    <TimePicker
                      showSecond={false}
                      disabledHours={disabledHours}
                      minuteStep={30}
                      placeholder="Preferred meeting time"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                      onChange={(value) => setIdeal_time(value ? value.format('HH:mm') : '')}
                      onBlur={() => setIsTimeTouched(false)}
                      required
                    />
                    {isTimeTouched && (
                      <span className="text-red-500 text-sm">Please select Time</span>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 mt-4 md:mt-8">
                  <div>
                    <textarea
                      name="challenges"
                      value={challenges}
                      maxLength="1000"
                      onChange={(e) => setChallenges(e.target.value)}
                      placeholder="What challenges do you face as a worship leader?"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                    />
                  </div>
                  <div>
                    <textarea
                      name="needs"
                      value={needs}
                      maxLength="500"
                      onChange={(e) => setNeeds(e.target.value)}
                      placeholder="Needs in worship space/community/congregation?"
                      className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none sm:text-sm sm:leading-6 bg-transparent opacity-70"
                    />
                  </div>
                </div>

                <div className="mt-4 md:mt-8 flex justify-center">
                  <button
                    type="submit"
                    className={`px-4 py-2 text-black rounded-lg ${
                      isSubmitting === false ? "bg-customGreen" : "submit-bg"}`}
                    disabled={isSubmitting} // Disable button if form is submitting
                  >
                    {paymentStatus === 'trail'
                      ? 'Submit'
                      : `Submit and pay R${calculateAmount()} ${selectedPlan === "2" ? "" : `monthly for ${cyclesNo} months`}`}
                  </button>
                </div>
              </form>
            </div>
            <div className="flex-grow w-full md:w-4/5 mx-auto">
              <ProgressWS name=" " disc="We only have a limited amount of Space" value={entryCount} max="100" />
            </div>
          </div>
          <div className="flex flex-col my-6">
            <div className="text-white mb-1 mt-6 text-white opacity-70 ml-1">
              <strong>What will this look like?</strong> <br /><br />
              When you sign up, you will receive a zoom link where we will have our meetings once a month.
              These meetings will range from 45 - 60 minutes and will be split into two sessions. <br />
              The first session will include teaching moments and a panel discussion. The second session will be a time of prayer and community.
              <br />
              There’s always more to share, but we will share more during the trial period from August - October.
            </div>
          </div>
        </div>
      </div>

      <DialogMessage
        open={open}
        onClose={() => setOpen(false)}
        title={dialogTitle}
        message={dialogMessage}
      />
    </>
  );
}

export default WorshipReg;
