import React, { useRef } from "react";
import bgvideo from "../assets/NEXT-logo.mp4";
import playButtonImage from "../assets/play-button.svg";

function Home({ scrollToMission, toggleNavbarVisibility }) {
  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    scrollToMission();
    toggleNavbarVisibility();
  };

  const handlePlayButtonClick = () => {
    console.log("clicked");
    const video = videoRef.current;
    // if (video.paused) {
    video.play();
    // }
  };

  return (
    <div className="home">
      <video
        id="bgvideo"
        className="video-background"
        muted
        autoPlay
        onEnded={handleVideoEnded}
        ref={videoRef}
      >
        <source src={bgvideo} type="video/mp4" />
      </video>

      <button className="play-button" onClick={handlePlayButtonClick}>
        <img src={playButtonImage} alt="Play" />
      </button>

      <div style={{ position: "absolute", paddingTop: "96vh" }}>
        <button
          style={{
            background: "#7FB241",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "rgb(127 178 65) 0px 0px 8px 4px",
          }}
          onClick={scrollToMission}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Home;
