import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import { Dialog } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";

function Connect() {
  const [selectedPlan, setSelectedPlan] = useState("2");
  const [selectedAmount, setSelectedAmount] = useState("R 250");
  const [cyclesNo, setCyclesNo] = useState("3");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [payemntStatus, setPaymentStatus] = useState("");
  const [itemName, setItemName] = useState("Donation");
  const [open, setOpen] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false); // State to manage visibility of custom amount input
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);


  const navigate = useNavigate();

  const handleAmountClick = (amount) => {
    setSelectedAmount(amount);
  };

  const handleOptionChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleCyclesChange = (cycles) => {
    setCyclesNo(cycles);
  };

  const handleItemName = (itemName) => {
    setItemName(itemName);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/success") {
      setOpen(true);
      setPaymentStatus("success");
    } else if (location.pathname === "/cancel") {
      setOpen(true);
      setPaymentStatus("failed");
    } else {
      setOpen(false);
    }
  }, [location.pathname]);


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+27|0)[1-9]\d{8}$/;

  return (
    <>
      <div className="py-14 bg-customBlack">
        <div className="container px-3 md:px-8 py-10 mx-auto">
          <div className="text-3xl md:text-5xl font-bold text-white text-center">
            Wanna help us BUILD <span style={{ color: "#7fb241" }}>?</span>
          </div>
          <div className="flex flex-col md:flex-row mt-10 md:mt-14 md:gap-12 p-3 md:p-0">
            <div className="flex-grow w-full md:w-2/5 ">
              <ProgressBar name="Production" disc="Funding for content production" width="5%" />
              <ProgressBar name="Development" disc="Reaching our Digital Platform MVP goal" width="10%" />
              <ProgressBar name="Operation" disc="Keeping it Alive for the Kingdom" width="15%" />
            </div>

            <div className="flex-grow w-full md:w-3/5 mt-8 md:mt-0 md:ml-16">
              <form
                action="https://www.payfast.co.za/eng/process"
                //  action="https://sandbox.payfast.co.za​/eng/process"
                method="post"
              >
                {/* Payfast from */}
                <input type="hidden" name="merchant_id" value="17661491" /> 
                {/* <input type="hidden" name="merchant_id" value="10033012" /> */}
                <input
                  type="hidden"
                  name="merchant_key"
                  value="lyymgvppkik3s"
                />
                {/* <input
                  type="hidden"
                  name="merchant_key"
                  value="m8oucxumfetwl"
                /> */}
                <input
                  type="hidden"
                  name="return_url"
                  value="http://nextis.co.za/success"
                />
                <input
                  type="hidden"
                  name="notify_url"
                  value="http://nextis.co.za/success"
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  value="http://nextis.co.za/cancel"
                />
                <input
                  type="hidden"
                  name="amount"
                  value={selectedAmount.substring(1)}
                />
                <input type="hidden" name="item_name" value={itemName} />
                <input type="hidden" name="name_first" value={firstName} />
                <input type="hidden" name="name_last" value={lastName} />
                <input type="hidden" name="email_address" value={email} />
                <input type="hidden" name="cell_number" value={phoneNo} />
                {selectedPlan !== "2" && (
                  <>
                    <input type="hidden" name="subscription_type" value={"1"} />
                    <input
                      type="hidden"
                      name="frequency"
                      value={selectedPlan}
                    />
                     <input type="hidden" name="cycles" value={cyclesNo}/>
                  </>
                )}

                {/* Payfast from */}
                <div className="flex items-center justify-center">
  <div className="grid w-[30rem] grid-cols-2 gap-2 rounded-3xl border border-white border-opacity-70">
    <div>
      <input
        type="radio"
        name="payment-option"
        id="2"
        value="2"
        className="peer hidden"
        onChange={handleOptionChange}
        checked={selectedPlan === "2"}
      />
      <label
        htmlFor="2"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        One-Time
      </label>
    </div>

    <div>
      <input
        type="radio"
        name="payment-option"
        id="3"
        value="3"
        className="peer hidden"
        onChange={handleOptionChange}
        checked={selectedPlan === "3"}
      />
      <label
        htmlFor="3"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        Monthly
      </label>
    </div>

    {/* Added flex-grow class <div>
      <input
        type="radio"
        name="payment-option"
        id="6"
        value="6"
        className="peer hidden"
        onChange={handleOptionChange}
        checked={selectedPlan === "6"}
      />
      <label
        htmlFor="6"
        className="block cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen peer-checked:text-black text-white text-opacity-70"
      >
        Annually
      </label>
    </div> */}
  </div>
</div>
{selectedPlan === "3" && (
  <div className="flex justify-center items-center w-full p-2">
    <div className="flex justify-between w-full max-w-sm border border-white border-opacity-70 rounded-3xl">
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNo"
          id="3_months"
          value="3"
          className="peer hidden"
          onChange={(e) => handleCyclesChange(e.target.value)}
        />
        <label
          htmlFor="3_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNo === "3" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 3 months
        </label>
      </div>
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNo"
          id="6_months"
          value="6"
          className="peer hidden"
          onChange={(e) => handleCyclesChange(e.target.value)}
        />
        <label
          htmlFor="6_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNo === "6" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 6 months
        </label>
      </div>
      <div className="flex justify-center items-center">
        <input
          type="radio"
          name="cyclesNo"
          id="12_months"
          value="12"
          className="peer hidden"
          onChange={(e) => handleCyclesChange(e.target.value)}
        />
        <label
          htmlFor="12_months"
          className={`block cursor-pointer select-none rounded-3xl p-2 text-center text-xs ${
            cyclesNo === "12" ? "bg-customGreen text-black" : "text-white opacity-70"
          }`}
        >
          over 12 months
        </label>
      </div>
    </div>
  </div>
)}
<div className="flex justify-center items-center w-full mt-4">
  <div className="grid grid-cols-3 gap-2 rounded-3xl border border-white border-opacity-70">
    <div>
      <input
        type="radio"
        name="item-name-option"
        id="Production"
        value="Production"
        className="peer hidden"
        onChange={() => handleItemName("Production")}
        checked={itemName === "Production"}
      />
      <label
        htmlFor="Production"
        className="block text-xs cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        Production
      </label>
    </div>

    <div>
      <input
        type="radio"
        name="item-name-option"
        id="Development"
        value="Development"
        className="peer hidden"
        onChange={() => handleItemName("Development")}
        checked={itemName === "Development"}
      />
      <label
        htmlFor="Development"
        className="block text-xs cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        Development
      </label>
    </div>
    <div>
      <input
        type="radio"
        name="item-name-option"
        id="Operation"
        value="Operation"
        className="peer hidden"
        onChange={() => handleItemName("Operation")}
        checked={itemName === "Operation"}
      />
      <label
        htmlFor="Operation"
        className="block text-xs cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-customGreen  peer-checked:text-black text-white text-opacity-70"
      >
        Operation
      </label>
    </div>
  </div>
</div>


                <div className="mt-6 grid grid-cols-1 gap-x-3 md:gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3 ">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        placeholder="First Name"
                        className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 mt-3 md:mt-0">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        placeholder="Last Name"
                        className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-x-3 md:gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3 mt-3 md:mt-0">
                  <div className="mt-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      className={`block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70`}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => setIsEmailTouched(true)}
                    />
                    {isEmailTouched && !emailRegex.test(email) && (
                      <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3 mt-3 md:mt-0">
                  <div className="mt-2">
                    <input
                      type="tel"
                      name="phoneNo"
                      id="phoneNo"
                      placeholder="Phone Number"
                      className={`block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70`}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      onBlur={() => setIsPhoneTouched(true)}
                    />
                    {isPhoneTouched && !phoneRegex.test(phoneNo) && (
                      <p className="text-red-500 text-sm mt-1">Please enter a valid phone number.</p>
                    )}
                  </div>
                </div>
                </div>
                <div className="flex flex-wrap mt-4 gap-4">
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmount === "R 250"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(false); // Show the custom amount input box
      handleAmountClick("R 250"); // Update the selected amount to "Own Amount"
    }}
  >
    R 250
  </div>
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmount === "R 500"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(false); // Show the custom amount input box
      handleAmountClick("R 500"); // Update the selected amount to "Own Amount"
    }}
  >
    R 500
  </div>
  <div
    className={`px-6 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmount === "R 1,000"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(false); // Show the custom amount input box
      handleAmountClick("R 1,000"); // Update the selected amount to "Own Amount"
    }}
  >
    R 1,000
  </div>
  <div
    className={`px-5 py-2 border border-white text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmount === "R 2,500"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(false); // Show the custom amount input box
      handleAmountClick("R 2,500"); // Update the selected amount to "Own Amount"
    }}
  >
    R 2,500
  </div>
  <div
    className={`px-4 py-2 border border-white  text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      selectedAmount === "R 5,000"
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(false); // Show the custom amount input box
      handleAmountClick("R 5,000"); // Update the selected amount to "Own Amount"
    }}
  >
    R 5,000
  </div>
  <div
    className={`px-4 py-2 border border-white  text-md uppercase rounded-lg outline-none shadow-lg cursor-pointer ${
      showCustomAmount === true
        ? "bg-customGreen text-black border-0 "
        : "opacity-70 text-white"
    }`}
    onClick={() => {
      setShowCustomAmount(true); // Show the custom amount input box
      handleAmountClick(""); // Update the selected amount to "Own Amount"
    }}
  >
    Own :
  </div>
  {showCustomAmount && (
        <div className="pt-4 flex w-full">
          <div className="flex items-center justify-between w-full">
            <input
              type="text"
              id="custom-amount"
              name="custom-amount"
              className="block w-full rounded-md border-1 py-2.5 px-4 shadow-sm ring-1 ring-inset text-white ring-white placeholder:text-slate-300 focus:outline-none  sm:text-sm sm:leading-6 bg-transparent opacity-70"
              placeholder="Enter amount"
              onChange={(e) => handleAmountClick("R " + e.target.value)}
            />
          </div>
        </div>
      )}
    </div>

                <div className="pt-4 flex w-full">
                  <button
                    type="submit"
                    className="bg-customGreen  rounded-md w-full py-2 text-md"
                    // onClick={() => setOpen(true)}
                  >
                     {`Give ${selectedAmount} ${selectedPlan === "2" ? "" : `monthly for ${cyclesNo} months`}`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Dialog as="div" className="relative z-10 " open={open} onClose={setOpen}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative bg-gray-900 transform overflow-hidden rounded-2xl text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xs ">
              <div
                className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                style={{ background: "#1A1A1A" }}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="mx-auto flex h-18 w-18 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-18 sm:w-18">
                    {payemntStatus === "success" ? (
                      <div
                        style={{
                          background: "#7FB241",
                          height: "70px",
                          width: "70px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "rgb(127 178 65) 0px 0px 8px 4px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 12.75 6 6 9-13.5"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "#F73131",
                          height: "70px",
                          width: "70px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "rgb(247 50 50 / 87%) 0px 0px 8px 4px",
                        }}
                      >
                        <div className="text-4xl text-white"> ×</div>
                      </div>
                    )}
                  </div>
                  <div className="mt-6 text-center ">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white opacity-80"
                    >
                      {payemntStatus === "success"
                        ? "Payment Sucessfull"
                        : "Payment Error"}
                    </Dialog.Title>
                  </div>
                </div>
              </div>
              <div
                className=" px-4 py-3 flex flex-row-reverse sm:px-6 justify-center"
                style={{ background: "#1A1A1A" }}
              >
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-customGreen text-black px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto"
                  onClick={() => {
                    navigate("/");
                    setOpen(false);
                  }}
                >
                  Done
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Connect;
